/* global BUILD_TYPE */
/* eslint global-require: "off" */
import 'focus-visible';
import 'element-matches-polyfill';
import 'core-js/es/map';
import 'core-js/es/set';
import 'intersection-observer';

if (BUILD_TYPE === 'legacy') {
    require('core-js/es/symbol');
    require('core-js/es/array/includes');
    require('core-js/es/array/find-index');
    require('core-js/es/object/assign');
    require('core-js/es/promise/finally');
    require('custom-event-polyfill');
    require('picturefill');
    require('whatwg-fetch');
    require('classlist-polyfill');
}
